import React from "react";
import * as Ui from "semantic-ui-react";

import styled from "styled-components";
import { AnswerObj } from "ewoq/containers/Interrogator";

const type: string = "TypeInput";

export interface Props {
  placeholder?: string;
  inputType: string;
  onValid: (state?: AnswerObj) => void;
  extraRadio?: string[];
  large?: boolean;
}

export interface State {
  text: string;
  radioAnswer?: number;
}

const Squeeze = styled.div`
  margin-left: 1.5em;
  margin-bottom: 0.5em;
`;

export class Question extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { text: "", radioAnswer: undefined };
  }

  handleInput = (a: any, b: any) => {
    let answer = b.value;

    if (answer === "") {
      this.setState(
        { text: answer, radioAnswer: undefined },
        () =>
          // this.props.onValid({ data: undefined, questionProps: this.props, type })
          this.props.onValid(undefined) //TODO
      );
    } else {
      if (this.props.large) {
        this.setState({ text: answer, radioAnswer: undefined });
      } else {
        this.setState({ text: answer, radioAnswer: undefined }, () =>
          this.props.onValid({
            data: this.state,
            questionProps: this.props,
            type
          })
        );
      }
    }
  }; // TODO, make on valid only happen when leaves focus and there is an answer

  handleRadio = (idx: number) => {
    this.setState({ radioAnswer: idx, text: "" }, () =>
      this.props.onValid({ data: this.state, questionProps: this.props, type })
    );
  };

  onDone = () => {
    this.props.onValid({ data: this.state, questionProps: this.props, type });
  };

  render() {
    return (
      <Ui.Form>
        {this.props.large ? (
          <div>
            <Ui.Form.Field inline>
              <Ui.TextArea
                placeholder={this.props.placeholder}
                onInput={this.handleInput}
                value={this.state.text}
              />
            </Ui.Form.Field>
            <Ui.Form.Field>
              <Ui.Button onClick={this.onDone}>Done</Ui.Button>
            </Ui.Form.Field>
          </div>
        ) : (
          <div>
            {" "}
            <Ui.Form.Field inline>
              <Ui.Input
                placeholder={this.props.placeholder}
                type={this.props.inputType}
                onChange={this.handleInput}
                value={this.state.text}
              />
            </Ui.Form.Field>
          </div>
        )}

        {this.props.extraRadio ? (
          <Squeeze>
            <Ui.Divider hidden />
            {this.props.extraRadio.map((option, idx) => (
              <Ui.Form.Field inline key={idx}>
                <Ui.Radio
                  radio
                  label={option}
                  value={option}
                  checked={
                    !(this.state.text.length > 0) &&
                    idx === this.state.radioAnswer
                  }
                  onClick={() => this.handleRadio(idx)}
                />
              </Ui.Form.Field>
            ))}
          </Squeeze>
        ) : null}
      </Ui.Form>
    );
  }
}

export default Question;
