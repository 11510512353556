import React from "react";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";
import * as Ui from "semantic-ui-react";

import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";

import Happy from "ewoq/prototypes/icons/happy.png";
import Sad from "ewoq/prototypes/icons/sad.png";
import Excited from "ewoq/prototypes/icons/excited.png";
import Afraid from "ewoq/prototypes/icons/fear.png";
import Angry from "ewoq/prototypes/icons/angry.png";

import styled from "styled-components";
const Smiley = styled.span`
  width: 65%;
  min-width: 2em;
`;

export const feelings = (v: any) => (
  <SliderGroup.Question
    onValid={v}
    subQuestions={[
      {
        // question: (
        //   <Smiley>
        //     <Ui.Image src={Excited} />
        //   </Smiley>
        // ),
        labels: ["Not at all", "Interested", "Excited"]
      },
      {
        // question: (
        //   <Smiley>
        //     <Ui.Image src={Happy} />
        //   </Smiley>
        // ),
        labels: ["Not at all", "Happy", "Cheerful"]
      },
      {
        // question: (
        //   <Smiley>
        //     <Ui.Image src={Sad} />
        //   </Smiley>
        // ),
        labels: ["Not at all", "Sad", "Crying"]
      },
      {
        // question: (
        //   <Smiley>
        //     <Ui.Image src={Angry} />
        //   </Smiley>
        // ),
        labels: ["Not at all", "Irritated", "Angry"]
      },
      {
        // question: (
        //   <Smiley>
        //     <Ui.Image src={Afraid} />
        //   </Smiley>
        // ),
        labels: ["Not at all", "Concerned/Nervous", "Afraid"]
      }
    ]}
  />
);

export const extraFeelings = (v: any) => (
  <MultipleChoice.Question
    onValid={v}
    options={[
      { text: "Busy" },
      { text: "Frustrated" },
      { text: "Embarrassed" },
      { text: "Confused" },
      { text: "Disgusted" },
      { text: "Bored" },
      { text: "Lonely" },
      { text: "Other", extraInput: "string" }
    ]}
  />
);
