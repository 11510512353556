import * as React from "react";

// This module contains facilities for translating content.
// The desired language is set by using the Provider like so:
// <LanguageProvider value="en">all multilingual content here </LanguageProvider>
//
// Inside the language provider, language-specific components can be created
// and will be shown only if they match the Provider's language.
// For example:
// <L l='en'>Hello</L> <L l='ch'>Gruetzi>/L> will render only one salutation
// based on the language set in the Provider that encloses them.
//
// a second mechanism is provided for translating non-component objects.
// a dictionary with shape language->any can be passed to the l function, and
// it will return the value corresponding to the language set in the provider.
// For example:
// l({"en":"Hello", "ch": "Gruetzi"}) will return the string "Hello"  or the string "Gruetzi"
// based on the langauge set in the last Provider that was invoked.

// CAVEAT: the language in this case is a global variable (window.currentLanguage)
// so the behaviour of the l function is global and not dependent on the
// closes LanguageProvider ancestor.

export const LanguageContext = React.createContext("en");
(window as any).currentLanguage = "en";

export const LanguageProvider: React.SFC<{ value: string }> = p => {
  (window as any).currentLanguage = p.value;
  return <LanguageContext.Provider value={p.value} children={p.children} />;
};

interface Props {
  l: string;
}

export class L extends React.Component<Props> {
  static contextType = LanguageContext;

  render() {
    return this.props.l === this.context && this.props.children;
  }
}

export const En: React.SFC<{}> = c => <L l="en">{c.children}</L>;
export const De: React.SFC<{}> = c => <L l="de">{c.children}</L>;

interface TranslationObject {
  [language: string]: string;
}

export const l = (d: TranslationObject) => {
  const language = (window as any).currentLanguage;
  return language in d ? d[language] : d["en"];
};
