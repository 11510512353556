import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";

interface Props {
  picture: string;
  backgroundPicture?: string;
  labels?: string[];
  reset?: boolean;
  includeDone?: boolean;
  onValid: (state?: object) => void;
}

export interface State {
  value?: number;
  endOverrun: boolean;
  done: boolean;
}

const GlobalContainer = styled.div`
  height: 3em;
  max-width: 90%;
  width: 45em;
  margin: auto;
  min-height: 6em;
  position: relative;
  padding-top: 1em;
`;

const Range = styled.div`
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 1em;
  margin-top: 1em;
  background-color: #e8e8e8;
  border-radius: 5px;
`;

const Handle = styled.div`
  position: absolute;
  top: -2em; //-0.6em;
  z-index: 30;
  height: 5em;
  width: 5em;
  min-width: 7px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -2.5em;
`;

const HandlePic = styled.div`
  width: 100%;
  position: absolute;
  margin: auto;
`;

const BackgroundPic = styled.div`
  width: 150%;
  position: absolute;
  margin: auto;
`;

const MarksContainer = styled.div`
  z-index: 5;
  position: absolute;
  display: flex;
  justify-content: space-between;
  text-align: justify;
  margin-top: 3em;
`;

const MarkContainer = styled.div`
  text-align: center;
  width: 10rem;
`;

const ButtonArea = styled.div`
  margin-top: 3em;
`;

export class Slider extends React.Component<Props, State> {
  myRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    const done =
      this.props.includeDone === true || this.props.includeDone === undefined
        ? false
        : true;
    this.state = {
      value: undefined,
      endOverrun: false,
      done
    };
    this.myRef = React.createRef();
  }

  x2percentage = (x: number) => {
    const rect = (this.myRef.current as any).getBoundingClientRect();
    let value = (x - rect.left) / rect.width;

    value = Math.max(0, Math.min(1, value));
    this.setState({ endOverrun: x < rect.left });
    if (x < rect.left && this.state.endOverrun) {
    } else if (value === 0) {
      return;
    }
    this.setState({ value });
    if (this.state.done) {
      this.onDone();
    }
  };

  onDrag = (a: any) => this.x2percentage(a.pageX);
  onClick = (a: any) => {
    this.x2percentage(a.pageX);
  };

  onClickLabel = (idx: number) => () => {
    if (this.props.labels) {
      const value = ((100 / (this.props.labels.length - 1)) * idx) / 100;
      this.setState({ value });
    }
  };

  onDone = () => {
    this.props.onValid(this.state);
    this.setState({ done: true });
  };

  render() {
    let labelWidth = 1;
    if (this.props.labels) {
      labelWidth = 100 / this.props.labels.length;
    }
    let displacement = 0;
    let picSize = 0;
    if (this.myRef.current) {
      const rect = (this.myRef.current as any).getBoundingClientRect();
      displacement = this.state.value ? this.state.value * rect.width : 0;
      picSize = (displacement / (rect.width + 0.5 * rect.width)) * 100 + 20;
    }
    return (
      <div>
        <GlobalContainer>
          <Range ref={this.myRef} onClick={this.onClick}>
            {
              <Handle
                draggable
                style={{
                  marginLeft: displacement
                }}
                onDrag={this.onDrag}
              >
                {!this.props.backgroundPicture ? null : (
                  <BackgroundPic>
                    <Ui.Image src={this.props.backgroundPicture} />
                  </BackgroundPic>
                )}

                <HandlePic style={{ width: picSize || 25 }}>
                  <Ui.Image src={this.props.picture} />
                </HandlePic>
              </Handle>
            }
          </Range>
          {!this.props.labels ? null : (
            <MarksContainer
              style={{
                width: 100 / (1 - 1 / this.props.labels.length) + "%",
                left:
                  -100 /
                    ((1 - 1 / this.props.labels.length) *
                      2 *
                      this.props.labels.length) +
                  "%" //-100 / (2 * this.props.labels.length) + "%"
              }}
            >
              {this.props.labels.map((m, idx) => (
                <MarkContainer key={idx} style={{ width: labelWidth + "%" }}>
                  <Ui.Label onClick={this.onClickLabel(idx)} pointing>
                    {" "}
                    {m}
                  </Ui.Label>
                </MarkContainer>
              ))}
            </MarksContainer>
          )}
        </GlobalContainer>
        {!this.state.done ? (
          <ButtonArea>
            <Ui.Button onClick={this.onDone}>Done</Ui.Button>
          </ButtonArea>
        ) : null}
      </div>
    );
  }
}

export default Slider;

//TODO, remove need for done button, by making onvalid apply only after mouse release
