import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";

import * as GQ from "./GroupQuestions";
import { AnswerObj } from "ewoq/containers/Interrogator";

// This takes a group question, and lets you repeat it as many times as you want
const type: string = "RepeatGroupQuestions";

export interface Props {
  onValid: (state?: AnswerObj) => void;
  subQuestions: GQ.SubQuestions[];
}

export interface AnswersCluster {
  cluster: GQ.Answer[];
}

interface State {
  clusters: AnswersCluster[];
}

const PlusArea = styled.div`
  margin-top: 1em;
`;
const ClusterArea = styled.div`
  margin-top: 1em;
`;

export class Questions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const placeHolder = { cluster: [{ id: "0", attemptsCount: 0, title: "" }] };
    this.state = { clusters: [placeHolder] };
  }

  renderCluster = (v: any) => {
    return <GQ.Questions onValid={v} subQuestions={this.props.subQuestions} />;
  };

  onValidInternal = (idx: number) => (s?: AnswersCluster) => {
    let allAnswers = this.state.clusters;
    if (allAnswers[idx] && s) {
      allAnswers[idx] = s;
    }
    this.setState({ clusters: allAnswers }, () =>
      this.props.onValid({ data: this.state, type })
    );
  };

  addCluster = () => {
    let allAnswers = this.state.clusters;
    allAnswers.push({
      cluster: [{ id: "next", attemptsCount: 0, title: "" }]
    });

    this.setState({ clusters: allAnswers });
  };

  render() {
    return (
      <div>
        {this.state.clusters.map((a, idx) => (
          <ClusterArea key={idx}>
            {this.renderCluster(this.onValidInternal(idx))}
          </ClusterArea>
        ))}
        <PlusArea>
          <Ui.Button onClick={this.addCluster} icon={"plus"}></Ui.Button>
        </PlusArea>
      </div>
    );
  }
}
