import React from "react";
import * as Ui from "semantic-ui-react";

interface Props {
  image?: string;
  header: string;
  meta?: string;
  href: string;
  contact?: string;
  participantTypes?: string[];
}

class Card extends React.Component<Props> {
  render() {
    return (
      <Ui.Card fluid>
        <Ui.Image src={this.props.image} wrapped />
        <Ui.Card.Content>
          <Ui.Card.Header>{this.props.header}</Ui.Card.Header>
          <Ui.Card.Meta></Ui.Card.Meta>
          <Ui.Card.Description>{this.props.children}</Ui.Card.Description>
        </Ui.Card.Content>
        <Ui.CardContent>
          {this.props.participantTypes &&
            this.props.participantTypes.map((label, idx) => (
              <Ui.Label key={idx} color="green" size="small">
                {label}
              </Ui.Label>
            ))}
        </Ui.CardContent>
        <Ui.Card.Content extra>
          <a href={this.props.href}>
            <Ui.Button size="mini">More Info</Ui.Button>
          </a>

          {this.props.contact ? (
            <a href={this.props.contact}>
              <Ui.Button floated="right" size="mini">
                <Ui.Icon name="mail" />
              </Ui.Button>
            </a>
          ) : null}
        </Ui.Card.Content>
      </Ui.Card>
    );
  }
}

export default Card;
