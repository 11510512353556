import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Input from "ewoq/prototypes/TypeInput";
import * as YesNoQ from "ewoq/prototypes/YesNo";
import { Answer } from "ewoq/prototypes/GroupQuestions";

const questions: QuestionType[] = [
  {
    id: "SR_RES_1",
    mandatory: true,
    plainTitle: "Late coffee?",
    title: (
      <span>
        Over the previous day, did you drink coffee (or other caffeinated
        drinks) after 4PM?
      </span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_RES_2",
    mandatory: true,
    plainTitle: "Nap?",
    title: <span>Did you take a nap?</span>,
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_RES_3",
    mandatory: true,
    plainTitle: "Party?",
    title: <span>Did you go to a late-night party?</span>,
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_RES_4",
    mandatory: true,
    plainTitle: "Alcohol?",
    title: <span>Did you drink more than 1 serving of alcohol?</span>,
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_RES_5",
    mandatory: true,
    plainTitle: "Cigarettes?",
    title: <span>Did you smoke cigarettes (or consume other nicotine)?</span>,
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_RES_6",
    mandatory: true,
    plainTitle: "Drugs or medication?",
    title: (
      <span>Did you take any prescription/medical or recreational drugs?</span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_RES_7",
    mandatory: true,
    plainTitle: "Other affects health?",
    title: <span>Did anything else affect your health?</span>,
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_RES_8",
    mandatory: true,
    plainTitle: "Other affects sleep?",
    title: (
      <span>Did anything else happen that probably affects your sleep?</span>
    ),
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_RES_8",
    mandatory: false,
    plainTitle: "Explaination of thing that affects sleep:",
    title: "If you feel comfortable telling us, please explain what happened:",
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_RES_8");
      return (
        a[idx].data !== undefined &&
        a[idx].data["answer"] === YesNoQ.AnswerType.yes
      );
    },
    component: v => <Input.Question large inputType="string" onValid={v} />
  }
];
export default questions;
