import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";
import * as Input from "ewoq/prototypes/TypeInput";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import * as Slider from "ewoq/prototypes/Slider";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";

import * as RankList from "ewoq/prototypes/RankList";

const questions: QuestionType[] = [
  {
    id: "DQ_1",
    mandatory: false,
    plainTitle: "Participation feelings: ",
    title: (
      <span>
        How did you experience participating for this whole experiment?
      </span>
    ),
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { labels: ["It was awful", "Neutral", "It was fantastic"] },
          { labels: ["Boring", "Neutral", "Interesting"] },
          { labels: ["Stressful", "Neutral", "Relaxing"] }
        ]}
        // extraRadio = {[{text: "Other", inputType: "string"}]} //TODOe make this a Comment separate question
      />
    )
  },

  {
    id: "DQ_2",
    mandatory: false,
    plainTitle: "Would you do it again?",
    title: <span>Would you do it again?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["No", "Maybe", "Yes"]}
      />
    )
  },
  {
    id: "DQ_3.1",
    mandatory: false,
    plainTitle: "Did you like the screening questionnaire?",
    title: <span>How did you like the screening questionnaire?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["I hated it", "It was fine", "It was great!"]}
      />
    )
  },
  {
    id: "DQ_3.2",
    mandatory: false,
    plainTitle: "Problems with SQ:",
    title: <span>Tick all that apply for the screening questionnaire: </span>,
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "It was too long" },
          { text: "It made me uncomfortable" },
          { text: "I thought the questions were unimportant" },
          { text: "It was hard to understand" },
          { text: "It was repetitive" },
          { text: "I couldn't answer some of the questions properly" }
        ]}
      />
    )
  },
  {
    id: "DQ_3.3",
    mandatory: false,
    plainTitle: "Honesty in SQ:",
    title: (
      <span>
        How accurate/honest were your answers in the screening questionnaire?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          "Total lies",
          "Somewhat inaccurate / untruthful",
          "Somewhat accurate / truthful",
          "100% accurate"
        ]}
      />
    )
  },
  {
    id: "DQ_4.1",
    mandatory: false,
    plainTitle: "Did you like the lifestyle questionnaire?",
    title: <span>How did you like the lifestyle questionnaire?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["I hated it", "It was fine", "It was great!"]}
      />
    )
  },
  {
    id: "DQ_4.2",
    mandatory: false,
    plainTitle: "Problems with LQ:",
    title: <span>Tick all that apply for the lifestyle questionnaire: </span>,
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "It was too long" },
          { text: "It made me uncomfortable" },
          { text: "I thought the questions were unimportant" },
          { text: "It was hard to understand" },
          { text: "It was repetitive" },
          { text: "I couldn't answer some of the questions properly" }
        ]}
      />
    )
  },
  {
    id: "DQ_4.3",
    mandatory: false,
    plainTitle: "Honesty LQ:",
    title: (
      <span>
        How accurate/honest were your answers in the lifestyle questionnaire?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          "Total lies",
          "Somewhat inaccurate / untruthful",
          "Somewhat accurate / truthful",
          "100% accurate"
        ]}
      />
    )
  },
  {
    id: "DQ_5.1.1",
    mandatory: false,
    plainTitle: "Did you like the regular sleep-wake schedule?",
    title: (
      <span>
        What did you think of the regular sleep-wake schedule during the
        preparation week?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["I hated it", "It was fine", "It was great!"]}
      />
    )
  },
  {
    id: "DQ_5.1.2",
    mandatory: false,
    plainTitle: "Ease of compliance:",
    title: (
      <span>
        How easy was it for you to comply with the regular sleep schedule?
      </span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Effortless", " Neutral", "I struggled a lot"]}
      />
    )
  },

  {
    id: "DQ_5.2.1",
    mandatory: false,
    plainTitle: "Did you like the sleep reports?",
    title: (
      <span>What did you think of the "sleep reports" in the mornings?</span>
    ),
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["I hated them", "They were fine", "They were great!"]}
      />
    )
  },

  {
    id: "DQ_5.2.2",
    mandatory: false,
    plainTitle: "Problems with SR:",
    title: <span>Tick all that apply to the Sleep Report: </span>,
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "It was too long" },
          { text: "It made me uncomfortable" },
          { text: "I thought the questions were unimportant" },
          { text: "It was hard to understand" },
          { text: "It was repetitive" },
          { text: "I couldn't answer some of the questions properly" }
        ]}
      />
    )
  },
  {
    id: "DQ_6.1",
    mandatory: false,
    plainTitle: "Did you like the watch?",
    title: <span>Did it bother you to wear the watch?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Not at all", "A lot"]}
      />
    )
  },
  {
    id: "DQ_7.1",
    mandatory: false,
    plainTitle: "Did you like the cognitive test?",
    title: <span>What did you think of the cognitive test?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["I hated it", "It was fine", "It was great!"]}
      />
    )
  },
  {
    id: "DQ_7.2",
    mandatory: false,
    plainTitle: "Challenging cognitive tests?",
    title: <span>Did you find it challenging?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["No", "Yes"]}
      />
    )
  },
  {
    id: "DQ_8.1",
    mandatory: false,
    plainTitle: "EEG cap comfort:",
    title: <span>Was it ok to wear the EEG cap?</span>,
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "I'll never do it again" },
          { text: "It was uncomfortable" },
          { text: "It was ok" },
          { text: "I barely noticed it" }
        ]}
      />
    )
  },
  {
    id: "DQ_8.2",
    mandatory: false,
    plainTitle: "EEG cap pain:",
    title: (
      <span>Did you feel any of the following while wearing the EEG cap?</span>
    ),
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          { text: "Itchiness" },
          { text: "Too much pressure" },
          { text: "Unspecified pain" },
          { text: "Other", extraInput: "string" }
        ]}
      />
    )
  },
  {
    id: "DQ_9",
    mandatory: false,
    plainTitle: "Other comments:",
    title: <span>Other comments on your experience:</span>,
    component: v => <Input.Question onValid={v} large inputType="string" />
  }
];
export default questions;
