import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";

interface Props {
  onClick: (value: number) => void;
  labels?: string[];
}

export interface State {
  value?: number;
  endOverrun: boolean;
  isFirstContact: boolean;
}

const GlobalContainer = styled.div`
  max-width: 90%;
  width: 45em;
  margin: auto;
  min-height: 2em;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Range = styled.div`
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 1em;
  line-height: 1em;
  // margin-top: 2em;
  margin: auto;
  background-color: #e8e8e8;
  border-radius: 5px;
`;

const Handle = styled.div`
  position: absolute;
  left: -0.9em; //half of the width
  top: -0.4em; //-0.6em;
  z-index: 30;
  height: 1.8em;
  width: 1.8em;
  min-width: 7px;
  background-color: #6434c9;
  border-radius: 5px;
`;

const MarksContainer = styled.div`
  z-index: 5;
  position: absolute;
  display: flex;
  justify-content: space-between;
  text-align: justify;
  top: -2em;
`;

const MarkContainer = styled.div`
  text-align: center;
  width: 10rem;
`;

export class Slider extends React.Component<Props, State> {
  myRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      value: undefined,
      endOverrun: false,
      isFirstContact: true
    };
    this.myRef = React.createRef();
  }

  x2percentage = (x: number) => {
    const rect = (this.myRef.current as any).getBoundingClientRect();
    let value = (x - rect.left) / rect.width;

    value = Math.max(0, Math.min(1, value));
    this.setState({ endOverrun: x < rect.left });
    if (x < rect.left && this.state.endOverrun) {
    } else if (value === 0) {
      return;
    }
    this.setState({ value }, () => this.props.onClick(value));
  };

  onDrag = (a: any) => this.x2percentage(a.pageX);
  onClick = (a: any) => {
    this.x2percentage(a.pageX);
    this.setState({ isFirstContact: false });
  };

  onClickLabel = (idx: number) => () => {
    if (this.props.labels) {
      const value = ((100 / (this.props.labels.length - 1)) * idx) / 100;
      this.setState({ value, isFirstContact: false }, () =>
        this.props.onClick(value)
      );
    }
  };

  render() {
    let labelWidth = 0;
    if (this.props.labels) {
      labelWidth = 100 / this.props.labels.length;
    }
    let displacement = 0;
    if (this.myRef.current) {
      const rect = (this.myRef.current as any).getBoundingClientRect();
      displacement = this.state.value ? this.state.value * rect.width : 0;
      // displacement -= (rect.width * 2) / 100;
    }
    return (
      <GlobalContainer>
        {this.props.labels ? (
          <MarksContainer
            style={{
              width: 100 / (1 - 1 / this.props.labels.length) + "%",
              left:
                -100 /
                  ((1 - 1 / this.props.labels.length) *
                    2 *
                    this.props.labels.length) +
                "%" //-100 / (2 * this.props.labels.length) + "%"
            }}
          >
            {this.props.labels.map((l, idx) => (
              <MarkContainer key={idx} style={{ width: labelWidth + "%" }}>
                <Ui.Label pointing="below" onClick={this.onClickLabel(idx)}>
                  {l}
                </Ui.Label>
              </MarkContainer>
            ))}
          </MarksContainer>
        ) : null}
        <Range ref={this.myRef} onClick={this.onClick}>
          {!this.state.isFirstContact && (
            <Handle
              draggable
              style={{ marginLeft: displacement }}
              onDrag={this.onDrag}
            />
          )}
        </Range>
      </GlobalContainer>
    );
  }
}

export default Slider;
