import React from "react";
import { Interrogator, QuestionsObj } from "ewoq/containers/Interrogator";
import questions from "./Questions/FeelingsQuestions";
import { answerQuestionnaire } from "ewoq/api";

interface Props {
  onDone?: () => void;
  viewAll?: boolean;
  name?: string;
}

class Feelings extends React.Component<Props> {
  onSubmit = (answers: QuestionsObj) => {
    answerQuestionnaire("REB", this.props.name + "-SleepReport-Feelings", {
      answers: answers
    });

    this.props.onDone && this.props.onDone();
  };

  render() {
    return (
      <div>
        <Interrogator
          questions={questions}
          onDone={this.onSubmit}
          viewAll={this.props.viewAll}
        />
      </div>
    );
  }
}

export default Feelings;
