import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import * as SliderHorizontal from "./SliderHorizontal";
import * as SliderPicture from "./SliderPicture";
import * as SliderVertical from "./SliderVertical";
import { AnswerObj } from "ewoq/containers/Interrogator";

const type: string = "Slider";

export enum SliderType {
  vertical = "vertical",
  horizontal = "horizontal",
  picture = "picture"
}

interface Props {
  sliderType: SliderType;
  labels: string[];
  onValid: (state?: AnswerObj) => void;
  extraRadio?: string[];
  picture?: string;
  backgroundPicture?: string;
  includeDone?: boolean;
}

interface State {
  answer?: number;
  radioAnswer?: number;
}

const Squeeze = styled.div`
  margin-left: 1.5em;
  margin-top: 0.5em;
`;

export class Question extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      answer: undefined,
      radioAnswer: undefined
    };
  }

  onChange = (s: SliderHorizontal.State) => {
    this.setState({ answer: s.value, radioAnswer: undefined }, () =>
      this.props.onValid({ data: this.state, questionProps: this.props, type })
    );
  };

  onRadioChange = (idx: number) => {
    this.setState({ radioAnswer: idx, answer: undefined }, () =>
      this.props.onValid({ data: this.state, questionProps: this.props, type })
    );
  };

  showSlider() {
    switch (this.props.sliderType) {
      case "horizontal" || undefined:
        return this.sliderHorizontal(this.onChange);

      case "vertical":
        return this.sliderVertical(this.onChange);

      case "picture":
        return this.sliderPicture(this.onChange);
    }
  }

  sliderHorizontal = (v: any) => (
    <SliderHorizontal.Slider
      labels={this.props.labels}
      onValid={v}
      reset={this.state.radioAnswer !== undefined}
    />
  ); //TODO, see with Simone if this is the only way to do this

  sliderVertical = (v: any) => (
    <SliderVertical.Slider labels={this.props.labels} onValid={v} />
  );

  sliderPicture = (v: any) => (
    <div>
      <SliderPicture.Slider
        labels={this.props.labels.length > 1 ? this.props.labels : undefined}
        onValid={v}
        reset={this.state.radioAnswer !== undefined}
        picture={this.props.picture || "default"}
        backgroundPicture={this.props.backgroundPicture}
        includeDone={this.props.includeDone}
      />
    </div>
  );

  render() {
    return (
      <div>
        {this.showSlider()}
        {this.props.extraRadio ? (
          <Squeeze>
            <Ui.Form>
              {this.props.extraRadio.map((option, idx) => (
                <Ui.Form.Field inline key={idx}>
                  <Ui.Radio
                    radio
                    label={option}
                    value={option}
                    checked={idx === this.state.radioAnswer}
                    onClick={() => this.onRadioChange(idx)}
                  />
                </Ui.Form.Field>
              ))}
            </Ui.Form>
          </Squeeze>
        ) : null}
      </div>
    );
  }
}
