export const str2Time = (s: string) => {
  if (s == null) return 0;
  const sp = s.split(":");
  const h = parseInt(sp[0]);
  const m = parseInt(sp[1]);
  return h + m / 60;
};

export function makeid(length: number) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
// hello worlds

export const getUserId = () => {
  const pair = document.cookie
    .split(";")
    .find(cookie => cookie.trim().startsWith("QUID="));

  if (!pair) {
    return null;
  }

  return pair.split("=")[1];
};

export const setUserId = (v: string) => {
  document.cookie = `QUID=${v}; Secure; SameSite=Strict;Max-Age=${60 *
    60 *
    24 *
    365}`;
};

(window as any)["getQUID"] = getUserId;
(window as any)["setQUID"] = setUserId;
