import React from "react";
import * as Slider from "ewoq/prototypes/Slider";

import * as Radio from "ewoq/prototypes/Radio";

export const kss = (v: any) => (
  <Slider.Question
    onValid={v}
    sliderType={Slider.SliderType.vertical}
    labels={[
      "Extremely alert",
      "Very alert",
      "Alert",
      "Rather alert",
      "Neither alert nor sleepy",
      "Some signs of sleepiness",
      "Sleepy, but no effort to keep awake",
      "Sleepy, some effort to keep awake",
      "Very sleepy, great effort to keep awake, fighting sleep"
    ]}
  />
);

export const standardKSS = (v: any) => (
  <Radio.Question
    onValid={v}
    options={[
      { text: "1 = Extremely alert" },
      { text: "2 = Very alert" },
      { text: "3 = Alert" },
      { text: "4 = Rather alert" },
      { text: "5 = Neither alert nor sleepy" },
      { text: "6 = Some signs of sleepiness" },
      { text: "7 = Sleepy, but no effort to keep awake" },
      { text: "8 = Sleepy, some effort to keep awake" },
      {
        text: "9 = Very sleepy, great effort to keep awake, fighting sleep"
      }
    ]}
  />
);
