import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";

interface Props {
  labels: string[];
  reset?: boolean;
  onValid: (state?: object) => void;
}

export interface State {
  value?: number;
  endOverrun: boolean;
  isFirstContact: boolean;
}

const GlobalContainer = styled.div`
  height: 30em;
  width: 100%;
  position: relative;
  min-height: 6em;
  margin: auto;
  // background-color: blue;
`;

const Range = styled.div`
  z-index: 10;
  position: absolute;
  left: 1em;
  width: 1em;
  height: 27em;
  margin-top: 1.5em;
  background-color: #e8e8e8;
  border-radius: 5px;
`;

const Handle = styled.div`
  position: absolute;
  z-index: 30;
  left: -0.4em;
  width: 1.8em;
  height: 1.8em;
  min-height: 7px;
  background-color: #6434c9;
  border-radius: 5px;
  top: -0.6em;
  // justify-content: center;
`;

const MarksContainer = styled.div`
  z-index: 5;
  position: relative;
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
`;

const MarkContainer = styled.div`
  z-index: 1;
  margin-left: 3em;
  height: 3em;
  line-height: 2.8rem;
`;

export class Slider extends React.Component<Props, State> {
  myRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      value: undefined,
      endOverrun: false,
      isFirstContact: true
    };
    this.myRef = React.createRef();
  }

  x2percentage = (y: number) => {
    const rect = (this.myRef.current as any).getBoundingClientRect();
    let value = (y - rect.y - window.pageYOffset) / rect.height;
    value = Math.max(0, Math.min(1, value));
    this.setState({ endOverrun: y < rect.y - window.pageYOffset });
    if (y < rect.y - window.pageYOffset && this.state.endOverrun) {
    } else if (value === 0) {
      return;
    }
    this.setState({ value });
    this.props.onValid({ value });
  };

  onDrag = (a: any) => this.x2percentage(a.pageY);
  onClick = (a: any) => {
    this.x2percentage(a.pageY);
    this.setState({ isFirstContact: false });
  };
  onClickLabel = (idx: number) => () => {
    const value = ((100 / (this.props.labels.length - 1)) * idx) / 100;
    this.setState({ value, isFirstContact: false }, () =>
      this.props.onValid(this.state)
    );
  };

  render() {
    let displacement = 0;
    if (this.myRef.current) {
      const rect = (this.myRef.current as any).getBoundingClientRect();
      displacement = this.state.value ? this.state.value * rect.height : 0;
      displacement -= (rect.height * 1.5) / 100; //figure out the logic?
    }
    return (
      <GlobalContainer>
        <Range ref={this.myRef} onClick={this.onClick}>
          {(!this.state.isFirstContact || this.props.reset) && (
            <Handle
              draggable
              style={{ marginTop: displacement }}
              onDrag={this.onDrag}
            />
          )}
        </Range>
        <MarksContainer>
          {this.props.labels.map((m, idx) => (
            <MarkContainer key={idx}>
              <Ui.Label pointing="left" onClick={this.onClickLabel(idx)}>
                {" "}
                {m}
              </Ui.Label>
            </MarkContainer>
          ))}
        </MarksContainer>
      </GlobalContainer>
    );
  }
}

export default Slider;
