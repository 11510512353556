import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Input from "ewoq/prototypes/TypeInput";
import * as RepeatGroupQuestions from "ewoq/prototypes/RepeatGroupQuestions";
import { threeHoursDay } from "Questionnaires/StandardQuestionComponents/TimeofDay";

import * as YesNoQ from "ewoq/prototypes/YesNo";

import { Answer } from "ewoq/prototypes/GroupQuestions";

const questions: QuestionType[] = [
  {
    id: "SR_FOO_1",
    mandatory: true,
    plainTitle: "Caffeine today?",
    title: "Did you consume any caffeine today?",
    explanation: "Including tea, cola, energy drinks, etc.",
    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_FOO_2",
    mandatory: false,
    plainTitle: "Caffeinated drinks list:",
    title: <span>Please list how many caffeinated drinks you had:</span>,
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_FOO_1");
      return (
        a[idx].data !== undefined &&
        a[idx].data["answer"] === YesNoQ.AnswerType.yes
      );
    },
    component: v => (
      <RepeatGroupQuestions.Questions
        onValid={v}
        subQuestions={[
          {
            id: "type",
            title: "Type:",
            component: v => (
              <Input.Question
                onValid={v}
                inputType="string"
                placeholder="e.g. cappuccino"
              />
            )
          },
          {
            id: "number",
            title: "How many?",
            component: v => (
              <Input.Question onValid={v} inputType="number" placeholder="1" />
            )
          },
          {
            id: "when",
            title: "When:",
            component: v => threeHoursDay(v, false)
          }
        ]}
      />
    )
  }
];
export default questions;
