import React from "react";
import * as Ui from "semantic-ui-react";

import * as SliderBar from "./SliderBar";

import styled from "styled-components";
import { AnswerObj } from "ewoq/containers/Interrogator";

const type: string = "SliderGroup";

interface Options {
  question?: any;
  labels?: string[];
}

export interface Props {
  subQuestions: Options[];
  onValid: (state?: AnswerObj) => void;
}

export interface State {
  answers: number[];
}

const SliderZone = styled.div`
  // margin-right: 5em;
  // margin-left: 5em;
  width: 45em;
  max-width: 90%;
`;

const QuestionZone = styled.div`
  // margin-right: -3em;
`;

export class Question extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      answers: Array(this.props.subQuestions.length).fill(undefined)
    };
  }

  onClick = (idx: number) => (value: number) => {
    let answers = this.state.answers;
    answers[idx] = value;
    this.setState({ answers });
    // JSON.stringify(this.props.subQuestions[0].question.children);
    if (answers.every(a => a !== undefined)) {
      this.props.onValid({
        data: this.state,
        questionProps: this.props.subQuestions,
        type
      }); //
    }
  };

  render() {
    return (
      <Ui.Grid>
        <Ui.Grid.Row />
        {this.props.subQuestions.map((q, idx) => (
          <Ui.GridRow centered key={idx}>
            <Ui.Grid.Column width={2} textAlign="center" verticalAlign="middle">
              <QuestionZone>{q.question}</QuestionZone>
            </Ui.Grid.Column>
            <Ui.Grid.Column width={13} verticalAlign="middle">
              <SliderZone>
                <SliderBar.Slider
                  onClick={this.onClick(idx)}
                  labels={q.labels}
                />
              </SliderZone>
            </Ui.Grid.Column>
          </Ui.GridRow>
        ))}
      </Ui.Grid>
    );
  }
}

//Maybe TODO: make onvalid happen with partial data if person skips question
