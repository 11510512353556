import styled from "styled-components";

export const QuestionTitle = styled.div`
  color: black;
  font-size: 1.2em;
  margin-bottom: 0.6em;
`;

export const QuestionDescription = styled.div`
  color: gray;
  // font-size: 1.4em;
  margin-bottom: 0.6em;
  text-align: justify;
`;

export const GenericAnswerContainer = styled.div`
  // font-size: 1.4em;
`;

export const QuestionArea = styled.div`
  margin-bottom: 1em;
`;
