import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import { AnswerObj } from "ewoq/containers/Interrogator";

const type: string = "Radio";

export interface Option {
  text: any;
  extraInput?: string; //type of input like string, number, or date or time
  placeHolder?: string;
}

export interface Props {
  options: Option[];
  value?: string;
  onValid: (state?: AnswerObj) => void;
}

export interface State {
  answer: any;
  extraText: string;
}

const ExtraInputContainer = styled.div<any>`
  display: inline-block;
  width: ${p => 4 + 0.7 * p.width}em;
`;

const Squeeze = styled.div`
  margin-left: 1.5em;
`;

export class Question extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { answer: null, extraText: "" };
  }

  setValidAndState = (s: any) => {
    this.setState(s, () =>
      this.props.onValid({ data: this.state, questionProps: this.props, type })
    );
  };

  chooseAnswer = (answer: number) => {
    this.setValidAndState({ answer: answer, extraText: "" });
  };

  chooseInput = (answer: number) => {
    this.setValidAndState({ answer, extraText: "" });
  };

  updateExtra = (a: any, b: any) => {
    this.setValidAndState({ extraText: b.value });
  };

  render() {
    return (
      <Squeeze>
        <Ui.Form>
          {this.props.options.map((option, idx) => (
            <Ui.Form.Field inline key={idx}>
              <Ui.Radio
                radio
                label={option.text}
                value={option.text}
                checked={idx === this.state.answer}
                onClick={() => this.chooseAnswer(idx)}
              />
              {option.extraInput ? (
                <ExtraInputContainer
                  width={
                    option.placeHolder
                      ? Math.max(
                          this.state.extraText.length,
                          option.placeHolder.length
                        )
                      : this.state.extraText.length
                  }
                >
                  {" "}
                  <Ui.Input
                    onChange={this.updateExtra}
                    onFocus={() => this.chooseInput(idx)}
                    value={
                      this.state.answer === idx ? this.state.extraText : ""
                    }
                    fluid
                    type={option.extraInput}
                    placeholder={option.placeHolder}
                  />
                </ExtraInputContainer>
              ) : null}
            </Ui.Form.Field>
          ))}
        </Ui.Form>
      </Squeeze>
    );
  }
}

export default Question;
