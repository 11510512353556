import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";

import { QuestionDescription, QuestionArea } from "./Misc";
import { AnswerObj } from "ewoq/containers/Interrogator";

const type: string = "GroupQuestions";

export interface Answer {
  data?: any;
  questionProps?: object;
  id: string;
  timestamp?: Date;
  attemptsCount: number;
  type?: string;
  title: string;
}

export interface SubQuestions {
  id: string;
  title: any;
  plainTitle?: string;
  explanation?: any;
  component: (onValidInternal: (state?: object) => void) => any;
}

const QuestionTitle = styled.div`
  color: black;
  font-size: 1.1em;
  margin-bottom: 0.6em;
`;

const SubQuestionArea = styled.div`
  // padding: 0.5em;
`;

export interface Props {
  subQuestions: SubQuestions[];
  onValid: (state?: AnswerObj) => void;
  includeDone?: boolean;
}

export interface State {
  allAnswers: Answer[];
  done: boolean;
}
const DoneBox = styled.div`
  margin: 1em;
`;

export class Questions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const answers: Answer[] = this.props.subQuestions.map(q => ({
      data: undefined,
      questionProps: undefined,
      id: q.id,
      title: q.plainTitle || q.title,
      timestamp: undefined,
      attemptsCount: 0,
      type: undefined
    }));
    const done =
      this.props.includeDone === true || this.props.includeDone === undefined
        ? false
        : true;
    this.state = {
      allAnswers: answers,
      done
    };
  }

  onValidInternal = (idx: number) => (s?: AnswerObj) => {
    const answer = this.state.allAnswers[idx];

    answer.data = s ? s.data : undefined;
    answer.questionProps = s ? s.questionProps : undefined;
    answer.attemptsCount++;
    answer.timestamp = new Date();
    answer.type = s ? s.type : undefined;

    const answers = this.state.allAnswers.slice();
    answers[idx] = answer;

    this.setState({ allAnswers: answers });

    if (this.state.done) {
      this.onDone();
    }
  };
  onDone = () => {
    this.setState({ done: true }, () =>
      this.props.onValid({ data: this.state, questionProps: this.props, type })
    );
  };

  render() {
    return (
      <Ui.Segment.Group raised>
        {this.props.subQuestions.map((q, idx) => (
          <Ui.Segment key={idx}>
            <SubQuestionArea>
              <QuestionArea>
                <QuestionTitle>{q.title}</QuestionTitle>
                <QuestionDescription>{q.explanation}</QuestionDescription>
              </QuestionArea>
              {q.component(this.onValidInternal(idx))}
            </SubQuestionArea>
          </Ui.Segment>
        ))}
        {!this.state.done ? (
          <DoneBox>
            <Ui.Button onClick={this.onDone}>Done</Ui.Button>
          </DoneBox>
        ) : null}
      </Ui.Segment.Group>
    );
  }
}

export default Questions;
