import React from "react";

import styled from "styled-components";
import * as Ui from "semantic-ui-react";
import { listQueue, ApiRequest } from "../api";

interface Props {}

export class Queue extends React.Component<Props> {
  render() {
    return (
      <Ui.Container textAlign="center">
        <Ui.Header> Queue of Submitted Questionnaires</Ui.Header>
        <ul>
          {listQueue().map(k => {
            const data = JSON.parse(localStorage[k]) as ApiRequest;
            return (
              <li>
                {/* <Ui.Button onClick={this.removeFromQueue(k)}>Remove</Ui.Button> */}
                {k},{" "}
                <Ui.Label color={data.isOnline ? "green" : "red"}>
                  {" "}
                  {data.isOnline ? "is Online" : "is not Online yet"}{" "}
                </Ui.Label>
              </li>
            );
          })}
        </ul>
      </Ui.Container>
    );
  }
}

export default Queue;
