import React from "react";
import * as Ui from "semantic-ui-react";
import { En, De, l } from "ewoq/components/Language";
import { AnswerObj } from "ewoq/containers/Interrogator";

const type: string = "YesNo";

export enum AnswerType {
  yes = "yes",
  no = "no",
  undetermined = "undetermined"
}

interface Props {
  onValid: (state?: AnswerObj) => void;
}

export interface State {
  answer: AnswerType;
}

export class Question extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      answer: AnswerType.undetermined
    };
  }
  setAnswer = (answer: AnswerType) => () => {
    this.setState({ answer }, () =>
      this.props.onValid({ data: this.state, questionProps: this.props, type })
    );
    document.removeEventListener("keydown", this.handleKeyPress, false);
  };

  handleKeyPress = (e: KeyboardEvent) => {
    switch (e.key) {
      case "1": {
        this.setAnswer(AnswerType.yes)();
        break; //use return if nothing else to run
      }
      case "2": {
        this.setAnswer(AnswerType.no)();
        break;
      }
    }
  };
  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress, false);
  }
  render() {
    return (
      <div>
        <Ui.Button.Group>
          <Ui.Button
            onClick={this.setAnswer(AnswerType.yes)}
            color={this.state.answer === AnswerType.yes ? "violet" : undefined}
          >
            <En>Yes</En>
            <De>Ja</De>
          </Ui.Button>
          <Ui.Button.Or text={l({ en: "or", de: "" })}></Ui.Button.Or>
          <Ui.Button
            onClick={this.setAnswer(AnswerType.no)}
            color={this.state.answer === AnswerType.no ? "violet" : undefined}
          >
            <En>No</En>
            <De>Nein</De>
          </Ui.Button>
        </Ui.Button.Group>
      </div>
    );
  }
}

export default Question;
