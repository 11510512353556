import styled from "styled-components";

export const HeaderContainer = styled.div`
  height: auto;
  min-height: 3.5em;

  font-size: 300%;
  padding: 0.5em;
  line-height: 1.2em;
  text-align: center;
`;
export const SectionBody = styled.div`
  text-align: justify;
  padding: 0.5em;
`;

export const ParticipantID = styled.div`
  text-align: center;
  color: #787878; //#b5b5b5;
  font-style: italic;
  font-size: 1.5em;
`;

export const Squeeze = styled.div`
  margin: auto;
  margin-bottom: 2em;
  margin-top: 2em;
  text-align: justify;

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 800px) {
    width: 100%;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 800px) {
    width: 70%;
  }
`;

export const LinkQ = styled.a`
  margin: 0.5em;
`;
