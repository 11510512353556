import React from "react";

import * as Ui from "semantic-ui-react";
import { Interrogator, QuestionsObj } from "ewoq/containers/Interrogator";
import questions from "./DebriefingQuestions";
import { answerQuestionnaire } from "ewoq/api";

import { getUserId } from "ewoq/utils";

import { HeaderContainer, ParticipantID } from "ewoq/components/miscStyles";

interface Props {
  viewAll?: boolean;
}

interface State {
  modalOpen: boolean;
}

export class Debriefing extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalOpen: false
    };
  }

  onSubmit = (answers: QuestionsObj) => {
    answerQuestionnaire("REB", "Debriefing", {
      answers: answers
    });
    this.setState({ modalOpen: true });
  };

  render() {
    return (
      <div>
        <ParticipantID>{getUserId()}</ParticipantID>
        <HeaderContainer>
          <Ui.Container textAlign="center">
            Debriefing Questionnaire
          </Ui.Container>
        </HeaderContainer>
        <Ui.Container>
          <Interrogator
            questions={questions}
            onDone={this.onSubmit}
            viewAll={this.props.viewAll}
          />
        </Ui.Container>
        <Ui.Modal open={this.state.modalOpen} basic>
          <Ui.Modal.Content>You're done!</Ui.Modal.Content>
        </Ui.Modal>
      </div>
    );
  }
}

export default Debriefing;
