import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Radio from "ewoq/prototypes/Radio";

import * as Range from "ewoq/prototypes/Ranges";
import * as YesNoQ from "ewoq/prototypes/YesNo";
import * as Input from "ewoq/prototypes/TypeInput";
import * as MultipleChoice from "ewoq/prototypes/MultipleChoice";
import * as Slider from "ewoq/prototypes/Slider";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";
import { Answer } from "ewoq/prototypes/GroupQuestions";

const questions: QuestionType[] = [
  {
    id: "SR_SLE_1.1",
    mandatory: true,
    plainTitle: "Sleep quality:",
    title: <span>How was your sleep?</span>,
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { labels: ["Terrible", "Standard", "Perfect"] },
          { labels: ["Superficial", "Deep"] },
          { labels: ["Fragmented", "Continuous"] },
          { labels: ["Restless", "Calm"] }
        ]}
      />
    )
  },
  {
    id: "SR_SLE_1.2",
    mandatory: true,
    plainTitle: "Current state: ",
    title: <span>How do you feel right now?</span>,
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { labels: ["Tired", "Refreshed"] },
          { labels: ["In a bad mood", "In a good mood"] },
          { labels: ["Apathetic", "Full of energy"] },
          { labels: ["Restless / tense", "Calm"] },
          { labels: ["Unfocused", "Concentrated"] }
        ]}
      />
    )
  },
  {
    id: "SR_SLE_2",
    mandatory: true,
    title: <span>How was your sleep environment?</span>,
    plainTitle: "Sleep environment:",
    component: v => (
      <SliderGroup.Question
        onValid={v}
        subQuestions={[
          { labels: ["Comfortable", "Uncomfortable"] },
          { labels: ["Too quiet", "Perfect", "Too noisy"] },
          { labels: ["Too cold", "Perfect", "Too warm"] },
          { labels: ["Too dark", "Perfect", "Too bright"] }
        ]}
      />
    )
  },
  {
    id: "SR_SLE_3.1",
    title: <span>Did anything disturb your sleep?</span>,
    plainTitle: "Sleep disturbances:",
    explanation: (
      <span>Did you notice something unusual (smells, sounds, etc)?</span>
    ),
    component: v => (
      <Input.Question
        onValid={v}
        inputType="string"
        placeholder="e.g. people talking"
        extraRadio={["No", "Maybe, but I don't know what"]}
      />
    )
  },

  {
    id: "SR_SLE_3.2",
    title: "When during the night were you disturbed?",
    plainTitle: "Disturbance timing:",
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_SLE_2");
      return a[idx].data !== undefined && a[idx].data["radioAnswer"] !== 0;
    },
    component: v => (
      <Range.Question
        onValid={v}
        labels={["Beginning", "Middle", "End", "Unsure"]}
      />
    )
  },
  {
    id: "SR_SLE_4",
    mandatory: true,
    title: <span>How long did it take you to fall asleep, approximately?</span>,
    plainTitle: "Time to fall asleep:",
    component: v => (
      <Input.Question onValid={v} inputType="number" placeholder="Minutes" />
    )
  },
  {
    id: "SR_SLE_5.1",
    mandatory: true,
    title: <span>How easy was it for you to fall asleep?</span>,
    plainTitle: "Ease of falling asleep:",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Extremely hard", "Extremely easy"]}
      />
    )
  },
  {
    id: "SR_SLE_5.2",
    mandatory: false,
    plainTitle: "Causes for difficulty falling asleep:",
    title: (
      <span>
        Did any of the following make it difficult to fall asleep once you
        turned the lights off?
      </span>
    ),
    shouldShow: (a: Answer[]) => {
      const idx = a.findIndex(a => a.id === "SR_SLE_5.1");
      return a[idx].data !== undefined && a[idx].data["answer"] < 0.5;
    },
    component: v => (
      <MultipleChoice.Question
        onValid={v}
        options={[
          {
            text:
              "Ruminating thoughts (couldn’t stop thinking about something over and over)"
          },
          { text: "Anxiety" },
          { text: "Excitement" },
          { text: "Not optimal sleep environment" },
          { text: "Not tired enough" },
          { text: "Something, but I don't know what" },
          { text: "Other", extraInput: "string" }
        ]}
      />
    )
  },
  {
    id: "SR_SLE_6.1",
    mandatory: false,
    plainTitle: "Waking in the night:",
    title: <span>Did you wake up in the night?</span>,
    explanation:
      "This refers to waking up any time before you were supposed to, and had enough time to go back to sleep.",
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "Never" },
          { text: "Once or twice" },
          { text: "Several times" },
          { text: "I barely slept" }
        ]}
      />
    )
  },
  {
    id: "SR_SLE_6.2",
    mandatory: false,
    plainTitle: "Confidence in waking at night:",
    title: <span>How sure are you about waking up in the night?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["0%", "100% sure"]}
      />
    )
  },
  {
    id: "SR_SLE_7.1",
    mandatory: false,
    plainTitle: "Wake up thirsty?",
    title: <span>Did you wake up thirsty?</span>,
    explanation:
      "This refers to both waking up in the middle of the night, and with the morning alarm.",

    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_SLE_7.2",
    mandatory: false,
    plainTitle: "Wake up hungry?",
    title: <span>Did you wake up hungry?</span>,

    component: v => <YesNoQ.Question onValid={v} />
  },
  {
    id: "SR_SLE_7.3",
    mandatory: false,
    plainTitle: "Wake up pain?",
    title: <span>Did you wake up with any aches or pain?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={[
          "I feel perfectly fine",
          "A few aches",
          "Some aches",
          "My whole body aches"
        ]}
      />
    )
  },
  {
    id: "SR_SLE_8",
    mandatory: false,
    plainTitle: "Time to wake up:",
    title: <span>How long did it take you to fully wake up?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        labels={["Instantly", "An hour", "2 hours", ">3 hours"]}
        sliderType={Slider.SliderType.horizontal}
        extraRadio={["I'm still not fully awake"]}
      />
    )
  },

  {
    id: "SR_SLE_9",
    mandatory: true,
    plainTitle: "Wake with alarm?",
    title: (
      <span>Did you wake up when the experimenter knocked in the morning?</span>
    ),
    component: v => (
      <Radio.Question
        onValid={v}
        options={[
          { text: "Yes" },
          {
            text: "I was already awake before the alarm went off"
          },

          {
            text:
              "Yes, but I had already woken up before and gone back to sleep"
          },
          { text: "Yes, but hitting snooze / with more than one alarm" },
          {
            text: "Yes, but then I went back to sleep and woke up spontaneously"
          },
          { text: "No, I slept through the alarm" }
        ]}
      />
    )
  },

  {
    id: "SR_SLE_10",
    mandatory: true,
    plainTitle: "Ease of waking up:",
    title: <span>How easy was it for you to wake up?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Extremely easy", "Extremely hard"]}
      />
    )
  }
];
export default questions;
