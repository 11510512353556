import React from "react";
import { Language } from "../misc";
import { LanguageProvider } from "ewoq/components/Language";
import * as Ui from "semantic-ui-react";

import styled from "styled-components";

import Dreams from "../SleepReport/Dreams";
import Feelings from "../SleepReport/Feelings";
import CurrentState from "../CurrentState";
import FoodDrinks from "../SleepReport/FoodDrinks";
import NeedRescheduling from "../SleepReport/NeedRescheduling";
import SleepAssessment from "../SleepReport/SleepAssessment";

import Debriefing from "../DebriefingQuestionnaire";
import Tiredness from "../SleepReport/Tiredness";

// This page is meant to see all the questionnaires together, so that I can both show them
// to people as needed, and to correct in case of a mistake

interface Questionnaire {
  questionniare: (onDone: () => void, viewAll?: boolean) => any;
  label: string;
  color: string;
}

enum QColors {
  screening = "violet",
  lifestyle1 = "green",
  lifestyle2 = "pink",
  sleepReport = "blue",
  currentState = "red",
  debriefing = "purple"
}

const Questionnaires: Questionnaire[] = [
  {
    label: "Dreams",
    color: QColors.sleepReport,
    questionniare: (v, viewAll) => (
      <Dreams name="redo" onDone={v} viewAll={viewAll} />
    )
  },

  {
    label: "Sleep Assessment",
    color: QColors.sleepReport,
    questionniare: (v, viewAll) => (
      <SleepAssessment onDone={v} name="redo" viewAll={viewAll} />
    )
  },
  {
    label: "Need to Reschedule",
    color: QColors.sleepReport,
    questionniare: (v, viewAll) => (
      <NeedRescheduling onDone={v} name="redo" viewAll={viewAll} />
    )
  },
  {
    label: "Feelings",
    color: QColors.sleepReport,
    questionniare: (v, viewAll) => (
      <Feelings onDone={v} name="redo" viewAll={viewAll} />
    )
  },

  {
    label: "Food and Drinks",
    color: QColors.sleepReport,
    questionniare: (v, viewAll) => (
      <FoodDrinks onDone={v} name="redo" viewAll={viewAll} />
    )
  },
  {
    label: "Tiredness",
    color: QColors.sleepReport,
    questionniare: (v, viewAll) => (
      <Tiredness onDone={v} name="redo" viewAll={viewAll} />
    )
  },
  {
    label: "Current State",
    color: QColors.currentState,
    questionniare: (v, viewAll) => (
      <CurrentState name="redo" onDone={v} viewAll={viewAll} />
    )
  },
  {
    label: "Debriefing",
    color: QColors.debriefing,
    questionniare: (v, viewAll) => <Debriefing viewAll={viewAll} />
  }
];

interface Props {}

interface State {
  questionnaire?: number;
  language: Language;
  viewAll: boolean;
}

const ButtonArea = styled.div`
margin: auto;
margin-bottom: 2em;
margin-top: 2em;
text-align: justify;

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 800px) {
  width: 100%;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 800px) {
  width: 70%;
`;

const SegmentBox = styled.div`
  margin: 1em;
`;

class Screening extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      questionnaire: undefined,
      language: Language.en,
      viewAll: true
    };
  }

  onClick = (idx: number) => () => {
    this.setState({ questionnaire: idx });
  };

  onDone = () => () => {
    this.setState({ questionnaire: undefined });
  };

  onLanguageChoice = (language: Language) => this.setState({ language });

  render() {
    return (
      <LanguageProvider value={this.state.language}>
        {this.renderInner()}
      </LanguageProvider>
    );
  }

  renderInner() {
    return (
      <div>
        {this.state.questionnaire === undefined ? (
          <SegmentBox>
            <Ui.Segment>
              <Ui.ButtonGroup size="mini">
                <Ui.Button
                  onClick={() => this.onLanguageChoice(Language.en)}
                  color={
                    this.state.language === Language.en ? "teal" : undefined
                  }
                >
                  <Ui.Flag name="gb" />
                </Ui.Button>
                <Ui.ButtonOr />
                <Ui.Button
                  onClick={() => this.onLanguageChoice(Language.de)}
                  color={
                    this.state.language === Language.de ? "teal" : undefined
                  }
                >
                  <Ui.Flag name="de" />
                </Ui.Button>
              </Ui.ButtonGroup>

              <Ui.ButtonGroup floated="right" size="mini">
                <Ui.Button
                  onClick={() => this.setState({ viewAll: true })}
                  color={this.state.viewAll ? "teal" : undefined}
                >
                  See all
                </Ui.Button>
                <Ui.ButtonOr />
                <Ui.Button
                  onClick={() => this.setState({ viewAll: false })}
                  color={!this.state.viewAll ? "teal" : undefined}
                >
                  Simulate
                </Ui.Button>
              </Ui.ButtonGroup>

              <Ui.Divider horizontal>All Questionnaires</Ui.Divider>

              <Ui.Form widths="equal">
                {Questionnaires.map((q, idx) => (
                  <ButtonArea key={idx}>
                    <Ui.Button
                      fluid
                      size="large"
                      color={q.color as any}
                      onClick={this.onClick(idx)}
                      inverted
                    >
                      {q.label}
                    </Ui.Button>
                  </ButtonArea>
                ))}
              </Ui.Form>
            </Ui.Segment>
          </SegmentBox>
        ) : (
          Questionnaires[this.state.questionnaire].questionniare(
            this.onDone(),
            this.state.viewAll
          )
        )}
      </div>
    );
  }
}

export default Screening;
