import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";
import LSMpic from "./pictures/facebook.jpg";
import ExpCard from "ewoq/components/ExperimentCard";

import { HeaderContainer } from "ewoq/components/miscStyles";

const CardList = styled.div`
  @media only screen and (min-width: 600px) {
    margin-left: 10em;
    margin-right: 10em;
  }
  margin: 0.5em;
`;

class Experiments extends React.Component {
  render() {
    return (
      <div>
        <HeaderContainer>
          <Ui.Container textAlign="center">Ongoing studies</Ui.Container>
        </HeaderContainer>

        <CardList>
          <Ui.Card.Group>
            <ExpCard
              image={LSMpic}
              header="Identification of local sleep markers in wake EEG"
              meta="by Sophia Snipes"
              href="./"
              contact="mailto: experimentLSM@sophia.science"
              participantTypes={["young adults", "healthy"]}
            >
              This is a sleep deprivation study...
            </ExpCard>
            <ExpCard
              header="Effect of sounds on sleep"
              href="./"
              meta="by Elena Krugliakova"
              contact="mailto: elena.krugliakova@kispi.uzh.ch"
              participantTypes={["young adults", "healthy", "right handed"]}
            >
              This study involves 3 normal nights in the sleep lab with high
              density EEG recording. To find out more, send Elena an email.
            </ExpCard>
          </Ui.Card.Group>
        </CardList>
      </div>
    );
  }
}

export default Experiments;
