import React from "react";
import * as SliderGroup from "ewoq/prototypes/SliderGroup";

export const fourEnergiesPast = (v: any) => (
  <SliderGroup.Question
    onValid={v}
    subQuestions={[
      { labels: ["Physically exhausted", "Jittery with energy to move"] },
      { labels: ["Emotionally drained, asocial", "Extremely social"] },
      { labels: ["Mentally exhausted", "Interested in anything"] },
      {
        labels: ["I hate what I did", "I felt satisfied with what I'd done"]
      }
    ]}
  />
);

export const fourEnergiesPresent = (v: any) => (
  <SliderGroup.Question
    onValid={v}
    subQuestions={[
      { labels: ["Physically exhausted", "Jittery with energy to move"] },
      { labels: ["Emotionally drained, asocial", "Extremely social"] },
      { labels: ["Mentally exhausted", "Interested in anything"] },
      {
        labels: [
          "I hate what I'm doing",
          "I feel satisfied with what I'm doing"
        ]
      }
    ]}
  />
);
