import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "semantic-ui-css/semantic.min.css";
import "./index.css";
import { obtainUserId, clearQueue, sendQueue } from "ewoq/api";

ReactDOM.render(<App />, document.getElementById("root"));

obtainUserId();
clearQueue();
sendQueue();
