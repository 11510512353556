import React from "react";
import { QuestionType } from "ewoq/containers/Interrogator";
import * as Slider from "ewoq/prototypes/Slider";
import { fourEnergiesPast } from "Questionnaires/StandardQuestionComponents/FourEnergies";
import { kss } from "Questionnaires/StandardQuestionComponents/KSS";

const questions: QuestionType[] = [
  {
    id: "SR_TIR_1",
    mandatory: true,
    plainTitle: "Time spent working yesterday:",
    title: (
      <span>
        How much of the previous day did you spend working / studying?
      </span>
    ),
    explanation: "Consider anything you don't consider fun or restful.",
    tip: "If you want, click on the labels to select exactly that answer",
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Didn't work", "Worked all the time"]}
      />
    )
  },
  {
    id: "SR_TIR_2.1",
    mandatory: true,
    plainTitle: "More or less tired than usual?",
    title: <span>Were you overall more or less tired than usual?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Less", "Usual", "More"]}
      />
    )
  },
  {
    id: "SR_TIR_2.2",
    mandatory: false,
    plainTitle: "Did you want to nap?",
    title: <span>Would you have liked to take a nap?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Not at all", "Absolutely"]}
      />
    )
  },
  {
    id: "SR_TIR_2.3",
    mandatory: false,
    plainTitle: "Wanted more coffee?",
    title: <span>Do you wish you could have drunk more coffee/caffeine?</span>,
    component: v => (
      <Slider.Question
        onValid={v}
        sliderType={Slider.SliderType.horizontal}
        labels={["Not at all", "Absolutely"]}
      />
    )
  },
  {
    id: "SR_TIR_3.1",
    mandatory: false,
    plainTitle: "Morning tiredness:",
    title: (
      <span>
        Please indicate how tired you felt on average in the <b>morning</b>:
      </span>
    ),
    component: v => kss(v)
  }, //TODO get this information from KSS file somehow
  {
    id: "SR_TIR_3.2",
    mandatory: false,
    plainTitle: "Evening tiredness:",
    title: (
      <span>
        Please indicate how tired you felt on average in the <b>evening</b>:
      </span>
    ),
    component: v => kss(v)
  },
  {
    id: "SR_TIR_4",
    mandatory: false,
    plainTitle: "Energy end of day:",
    title: <span>By the end of the day, how did you feel?</span>,
    component: v => fourEnergiesPast(v)
  }
];
export default questions;
