import React from "react";
import * as Ui from "semantic-ui-react";
import styled from "styled-components";

import { l } from "ewoq/components/Language";
import { AnswerObj } from "ewoq/containers/Interrogator";

const type: string = "MultipleChoice";

interface Option {
  text: any;
  extraInput?: string;
  placeHolder?: string;
}

export interface Props {
  options: Option[];
  allAbove?: boolean;
  onValid: (state?: AnswerObj) => void;
  includeDone?: boolean;
}

export interface State {
  answers: boolean[];
  extraTexts: string[];
  clearAll: boolean;
  checkAll: boolean;
  done: boolean;
}

const ExtraInputContainer = styled.div<any>`
  display: inline-block;
  width: ${p => 4 + 0.7 * p.width}em;
`;

const Squeeze = styled.div`
  margin-left: 1.5em;
  margin-bottom: 1em;
`;

export class Question extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const done =
      this.props.includeDone === true || this.props.includeDone === undefined
        ? false
        : true;
    this.state = {
      answers: Array(this.props.options.length).fill(false),
      extraTexts: Array(this.props.options.length).fill(""),
      clearAll: false,
      checkAll: false,
      done
    };
  }

  setValidAndAnswer = (idx: number) => {
    const answers = this.state.answers.slice();
    answers[idx] = !answers[idx]; // this flips the checked option
    this.setState({ answers }); // important that its inside, because set state could happen later

    if (answers[idx] === false) {
      // this clears the text and the check all option, since neither would be relevant any more
      const extraTexts = this.state.extraTexts.slice();
      extraTexts[idx] = "";
      this.setState({ extraTexts, checkAll: false });
    } else if (answers[idx] === true) {
      this.setState({ clearAll: false });
    }
    if (this.state.done) {
      this.onDone();
    }
  };

  setValidAndExtra = (idx: number) => (a: any, b: any) => {
    // this both checks the box and starts writing
    const extraTexts = this.state.extraTexts.slice();
    extraTexts[idx] = b.value;
    this.setState({ extraTexts });
    let answers = this.state.answers.slice();
    if (b.value.length > 0) {
      // makes things checked if there is content
      answers[idx] = true;
    } else {
      answers[idx] = false;
    }
    this.setState({ answers });
    if (this.state.done) {
      this.onDone();
    }
  };

  clearAll = () => {
    const clearAll = !this.state.clearAll;
    if (clearAll) {
      const answers = Array(this.props.options.length).fill(false); //sets the state to all falses
      this.setState({ clearAll, answers, checkAll: false });
    } else {
      this.setState({ clearAll });
    }
    if (this.state.done) {
      this.onDone();
    }
  };
  checkAll = () => {
    const checkAll = !this.state.checkAll;
    if (checkAll) {
      const answers = Array(this.props.options.length).fill(true);
      this.setState({ checkAll, answers, clearAll: false });
    } else {
      this.setState({ checkAll });
    }
    if (this.state.done) {
      this.onDone();
    }
  };

  onDone = () => {
    this.setState({ done: true }, () =>
      this.props.onValid({ data: this.state, questionProps: this.props, type })
    );
  };

  render() {
    return (
      <div>
        <Squeeze>
          <Ui.Form>
            {this.props.options.map((option, idx) => (
              <Ui.Form.Field inline key={idx}>
                <Ui.Checkbox
                  label={option.text}
                  value={option.text}
                  checked={this.state.answers[idx]}
                  onClick={() => this.setValidAndAnswer(idx)}
                />
                {option.extraInput ? (
                  <ExtraInputContainer
                    width={
                      option.placeHolder
                        ? Math.max(
                            this.state.extraTexts.length,
                            option.placeHolder.length
                          )
                        : this.state.extraTexts.length
                    }
                  >
                    {" "}
                    <Ui.Input
                      onChange={this.setValidAndExtra(idx)}
                      value={this.state.extraTexts[idx]}
                      fluid
                      type={option.extraInput}
                      placeholder={option.placeHolder}
                    />
                  </ExtraInputContainer>
                ) : null}
              </Ui.Form.Field>
            ))}
            {this.props.allAbove ? (
              <div>
                {" "}
                <Ui.Form.Field>
                  <Ui.Checkbox
                    label={l({
                      en: "All of the above",
                      de: "alles vorher Aufgefuehrte"
                    })}
                    checked={this.state.checkAll}
                    onClick={this.checkAll}
                  />
                </Ui.Form.Field>
                <Ui.Form.Field>
                  <Ui.Checkbox
                    label={l({ en: "None of the above", de: "Nichts davon" })}
                    checked={this.state.clearAll}
                    onClick={this.clearAll}
                  />
                </Ui.Form.Field>
              </div>
            ) : null}
          </Ui.Form>
        </Squeeze>
        {!this.state.done ? (
          <Ui.Button onClick={this.onDone}>Done</Ui.Button>
        ) : null}
      </div>
    );
  }
}

export default Question;
